import { defineStore } from 'pinia'
import nuxtStorage from 'nuxt-storage'
import { AssistantType, Language } from '~/enums'

export const useAssistantStore = defineStore('assistantStore', {
  state: () => ({
    assistantType: AssistantType.GENERAL,
    language: Language.DUTCH,
  }),
  actions: {
    setAssistantType(assistantType: AssistantType) {
      try {
        this.assistantType = assistantType
        nuxtStorage.localStorage.setData(
          'assistantType',
          assistantType,
          24,
          'h',
        )
      } catch (e) {
        console.log(e)
      }
    },
    setLanguage(language: Language) {
      try {
        this.language = language
        nuxtStorage.localStorage.setData('language', language, 24, 'h')
      } catch (e) {
        console.log(e)
      }
    },
  },
})
